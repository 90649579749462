<template>
  <div class="flix-checkin-box">
    <div v-if="client.check_in || client.check_out" @mouseenter="hover = true" @mouseleave="hover = false">
      <flixIcon id="ok-circle"/>
      <span class="flix-html-small" v-if="hover && client.check_in">&nbsp; {{ client.check_in }}</span>
      <span v-if="hover && client.check_in && client.check_out"><hr /></span>
      <span class="flix-html-small" v-if="hover && client.check_out"> {{ client.check_out }}</span>
    </div>
    <div v-else>
      <b-flix-icon icon="minus" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    client: Object
  },
  data () {
    return {
      hover: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-checkin-box
    min-height: 20px
    cursor: pointer
</style>
